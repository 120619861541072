*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body
{
    height: 100vh;
    width: 100vw;
    font-family: 'Roboto', 'Helvetica';
    /* background: linear-gradient(#000, #423242); */
    background: linear-gradient(#000, #594783);
    overflow-x: hidden;

    /* font-size: 62.5% */
}

h1, h2 {
    padding: 10px 0px;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}

h1 {
    font-weight: 300;
    font-size: 3.8rem;
    color: whitesmoke;
}

h2 {
    font-weight: 100;
    font-size: 1.5rem;
    margin-top: 70px;
    /* color: #3f3f3f; */
    color: rgb(241, 103, 53);
    border-top: 1px solid #806080;
}

.webgl
{
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    width: 100vw;
    height: 100vh;
    /* mix-blend-mode: exclusion; */
}

.container {
    width: 100vw;
    z-index: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0px 1rem;
    /* display: grid; */
    /* grid-template-columns: repeat(12, 1fr); */
}

#hero {
    /* grid-column: 1 / span 12; 
    height: 80vh;
    align-items: center; */
    /* padding-left: env(safe-area-insert-left); */
    /* width: 100%; */
    
}

#header {
    max-width: 15rem;
    margin-top: .5rem;
    padding: 1em;
}

#skills {
    max-width: 15rem;
    color: rgb(245, 179, 242);
    padding: 1em;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.specializing-header {
    width: 100%;
    margin-bottom: 0.5rem;
}

.skillbox {
    position: relative;
    width: 4.2em;
    height: 4.2em;
    outline: 1px solid #806080;
    line-height: 1.3em;
    text-align: center;
}

.skillbox>span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: .7em;
}

blockquote {
    font-weight: 100;
    padding: 15px;
    margin: 25px 0px;
    /* background-color: #FAFEFF; */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    border-left: 10px solid rgb(204, 46, 204);
    color: #ececec;
}

.ui-effect {
    /* outline: 1px solid #4e3a4e42; */
    backdrop-filter: blur(3px) brightness(90%);
}

section {
    grid-column: 7 / span 5; 
    height: 100vh;
}

/* LOADING BAR */
.loading-bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #fff;
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.5s;
    will-change: transform;
}

.loading-bar::after {
    position: absolute;
    content: "LOADING";
    font-size: .75rem;
    width:100%;
    height:100%;
    top: 100%;
    color:#fff
}

.loading-bar.ended {
    transform-origin: top right;
    transform: scaleY(0);
    transition: transform 0.5s ease-in-out;
}

/* MEDIA QUERIES */
/* h1 {
    font-size: 3rem;
}
h2 {
    font-size: 1.25rem;
} */
@media (min-width: 43.75em) {  
    .container {
        margin-left: 2em;
    }
    #header, #skills {
        max-width: 17rem;
        margin-top: .5rem;
    }

    .skillbox {
        width: 5rem;
        height: 5rem;
    }
}

@media (min-width: 62em) {
    h1 {
        font-size: 5rem;
    }
    h2 {
        font-size: 2rem;
    }
    #header, #skills {
        max-width: 25rem;
    }
    .skillbox {
        width: 7rem;
        height: 7rem;
    }
}

@media (min-width: 87em) {

}

